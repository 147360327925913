<template>
  <s-header :name='"-考点信息"'></s-header>
  <van-cell-group>
    <van-cell title="考点代码：" :value="state.schoolInfo.code" />
    <van-cell title="考点名称：" :value="state.schoolInfo.name" />
  </van-cell-group>
  <!-- <div style="margin-left:15px;font-size:13px;"><span style="color:red">说明：</span>请仔细核对以下信息是否正确，如不正确可直接修改</div><br/> -->
  <van-notice-bar wrapable left-icon="info-o">
    请仔细核对以下信息是否正确，如不正确可直接修改
  </van-notice-bar>

  <van-form @submit="onSubmit" :submit-on-enter="false">
    <van-field v-model="state.schoolInfo.user_name" name="user_name" label="主考名称" required placeholder="请填写主考名称"
      :rules="[{ required: true, message: '请填写主考名称' }]" />
    <van-field v-model="state.schoolInfo.city_name" readonly clickable name="city_name" label="地区选择" required
      placeholder="点击选择省市区" @click="state.showArea = true" :rules="[{ required: true, message: '请选择地区' }]" />
    <!-- 默认选中四川省 -->
    <van-popup v-model:show="state.showArea" position="bottom">
      <van-area :area-list="areaList" :value="state.value" @confirm="onConfirm" @cancel="state.showArea = false" />
    </van-popup>

    <van-field v-model="state.schoolInfo.address" label="详细地址" required name="address" placeholder="详细地址"
      :rules="[{ required: true, message: '请填写详细地址' }]" />

    <van-field v-model="state.schoolInfo.mobile" label="联系电话" required type="tel" name="mobile" placeholder="联系电话"
      :rules="[{ required: true, message: '请填写联系电话' }]" />

    <van-field v-model="state.schoolInfo.back_mobile" label="备用电话" type="tel" name="back_mobile" placeholder="备用电话" />

    <van-field v-model="state.schoolInfo.email" label="电子邮箱" required name="email" error-message="标准试卷发送至此邮箱,请如实填写。"
      :rules="[{ required: true, message: '标准试卷发送至此邮箱,请如实填写' }]" />

    <van-field name="check_status" label="是否允许查分">
      <template #input>
        <van-radio-group v-model="state.schoolInfo.check_status" direction="horizontal">
          <van-radio name="0">禁止</van-radio>
          <van-radio name="1">允许</van-radio>
        </van-radio-group>
      </template>
    </van-field><br />

    <van-field v-model="state.schoolInfo.province_id" label="省id" name="province_id" v-show="false" placeholder="电子邮箱" />

    <van-field v-model="state.schoolInfo.city_id" label="市id" name="city_id" v-show="false" placeholder="电子邮箱" />

    <van-field v-model="state.schoolInfo.district_id" label="区id" name="district_id" v-show="false" placeholder="电子邮箱" />
    <div style="margin: 10px;">
      <van-button block type="success" native-type="submit">
        <span>确认无误</span>
      </van-button>
    </div>
  </van-form>
</template>
<script>
import { onMounted, reactive } from 'vue';
import { Toast } from 'vant';
import { useRouter } from 'vue-router';
import sHeader from '@/components/Header';
import { areaList } from '@/common/js/area';
import { schoolInfo, editSchool } from '@/apis/user';
export default {
  components: {
    sHeader
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      username: '2222',
      password: 'ppp',
      radio: '1',
      value: '51',
      showArea: false,
      schoolInfo: {}
    });

    onMounted(async () => {
      // Toast.loading({
      //   message: '加载中...',
      //   forbidClick: true,
      //   duration:1000
      // });
      state.schoolInfo = await schoolInfo();
      console.log(state.schoolInfo);
    });

    const onSubmit = async (values) => {
      await editSchool(values);
      Toast('操作成功')
      setTimeout(() => {
        router.push({ path: '/dashboard' })
      }, 1000)
    };

    const onConfirm = (values) => {
      state.showArea = false;
      state.schoolInfo.city_name = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/');
      state.schoolInfo.province_id = values[0].code;
      state.schoolInfo.city_id = values[1].code;
      state.schoolInfo.district_id = values[2].code;
    };

    const showPopup = () => {
      console.log(state.show);
      state.show = true;
    };
    return {
      state,
      onConfirm,
      onSubmit,
      showPopup,
      areaList
    };
  },
};
</script>
<style  scoped>
::-webkit-input-placeholder {
  /* WebKit browsers，webkit内核浏览器 */
  color: red;
  font-size: 16px;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: red;
  font-size: 16px;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: red;
  font-size: 16px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: red;
  font-size: 16px;
}
</style>